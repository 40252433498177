import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api'; 
export const SignUp = () => {
    const navigate = useNavigate();
    const [submitted, setSubmitted] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [dealershipname, setDealershipName] = useState('');
    const [dealershipid, setDealershipId] = useState('');
    const [phonenumber, setPhoneNumber] = useState('');
    const [postalcode, setPostalCode] = useState('');
    const [city, setCity] = useState('');
    const [address, setaddress] = useState('');
    const [salesperson, setsalesperson] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [lastemailError, setLastEmailError] = useState('');
    const [lastphonenumberError, setPhoneNumberError] = useState('');
    const [lastpostalcodeError, setPostalCodeError] = useState('');
    const [cityError, setCityError] = useState('');
    const [salespersonError, setsalespersonError] = useState('');
    const [addressError, setsAddresError] = useState('');
    const [errors, setErrors] = useState({});
    const [hst, setHST] = useState('');
    const [rin, setRIN] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(false);
    const handleCheckboxChange = (event) => {
        if (isCheckboxEnabled) {
            setIsChecked(event.target.checked); // Update checkbox state only if enabled
          }
      };
      const handleOkClick = () => {
        setIsCheckboxEnabled(true); // Enable the checkbox
        setIsChecked(true); // Automatically check the checkbox
      };
    const handleSignUp = async (event) => {
        event.preventDefault();
        if (firstName.trim() === '') {
            setFirstNameError('First name cannot be empty');
        } else {
            setFirstNameError('');
        }

        if (lastName.trim() === '') {
            setLastNameError('Last name cannot be empty');
        } else {
            setLastNameError('');
        }

        if (email.trim() === '') {
            setLastEmailError('Email cannot be empty');
        }else {
            setLastEmailError('');
        }

        if (phonenumber.trim() === '') {
            setPhoneNumberError('Phone Number cannot be empty');
        } else {
            setPhoneNumberError('');
        }
        if (city.trim() === '') {
            setCityError('City cannot be empty');
        } else {
            setCityError('');
        }
        if (salesperson.trim() === '') {
            setsalespersonError('Salesperson Registration cannot be empty');
        } else {
            setsalespersonError('');
        }
        if (address.trim() === '') {
            setsAddresError('Dealer Address cannot be empty');
        } else {
            setsAddresError('');
        }

        if (firstName.trim() !== '' && lastName.trim() !== '' && email.trim() !== '' && phonenumber.trim() !== '' && city.trim() !== ''  && salesperson.trim() !== ''  && address.trim() !== '' ) {
            // Navigate to the desired page
           
        }
        try {
            const response = await api.post('/auth/dealer/register', {
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone: phonenumber,
                dealer_name: dealershipname,
                dealershipId: dealershipid,
                salesperson_registration: salesperson,
                dealer_address: city,
                dealer_full_address: address,
            });
    
            console.log('Registration successful:', response.data);
            setSubmitted(true);
            setTimeout(() => {
                navigate('/dealerin');
              }, 3000);
        }catch (error) {
            console.error('Error occurred during registration:', error);
            const errorMessage = error.response.data; // Log the error response
            setErrors(errorMessage);
        }
    };
     
    return (
        <section className="seller-section">
            <div className="container">
                <div className="header-logo">
                    <a href="/">
                        <img src="images/logo.png" alt="logo" />
                    </a>
                </div>
                <div className="seller-heading py-3">
                    <h3 className="main-heading text-center">Dealer Sign Up</h3>
                </div>
                <form onSubmit={handleSignUp}>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="First Name*"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                             {firstNameError && <div className="text-danger">{firstNameError}</div>}
                        </div>
                        <div className="form-group col-md-6">
                            <input type="text" 
                             className="form-control"
                             placeholder="Last Name*" 
                             value={lastName}
                             onChange={(e) => setLastName(e.target.value)}
                            />
                             {lastNameError && <div className="text-danger">{lastNameError}</div>}
                        </div>
                    </div>
                    <div className="form-group">
                        <input type="email" 
                        className="form-control"
                        id="inputEmail4"
                        placeholder="Email*" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        />
                         {lastemailError && <div className="text-danger">{lastemailError}</div>}
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">    
                            <input type="text" class="form-control" id="inputcompanyname" value={dealershipname}   onChange={(e) => setDealershipName(e.target.value)} placeholder="Dealership Name*"/>
                        </div>
                        <div class="form-group col-md-6">    
                            <input type="text" class="form-control" id="inputcompanyname" value={dealershipid}   onChange={(e) => setDealershipId(e.target.value)} placeholder="Dealership ID*"/>
                        </div>
                   </div>
                   <div className="form-group">
                        <input type="text" 
                        className="form-control" 
                        id="inputsalesperson"
                         placeholder="Salesperson Registration #"
                        value={salesperson}
                        onChange={(e) => setsalesperson(e.target.value)}
                        />
                         {salespersonError && <div className="text-danger">{salespersonError}</div>}
                    </div>
                    <div class="form-row">
                    <div className="form-group col-md-6">
                   <input type="text" 
                        className="form-control" 
                        id="inputaddress"
                         placeholder="Address*"
                        value={address}
                        onChange={(e) => setaddress(e.target.value)}
                        />
                         {addressError && <div className="text-danger">{addressError}</div>}
                   </div>
                    <div className="form-group col-md-6">
                        <input type="text" 
                        className="form-control" 
                        id="inputCity"
                         placeholder="City/Province/Postal Code*"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        />
                         {cityError && <div className="text-danger">{cityError}</div>}
                    </div>
                    </div>
                    <div className="form-group">
                        <input type="number" 
                        className="form-control"
                         id="inputphone" 
                         placeholder="Phone Number*"
                         value={phonenumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                        {lastphonenumberError && <div className="text-danger">{lastphonenumberError}</div>}
                    </div>
                    <p>Car-Chaser Technology needs the contact information you provide to us to contact you about our products and services. You may unsubscribe from these communications at any time. For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy, please review our Privacy Policy.</p>
                    {errors && Object.keys(errors).length > 0 && (
                        <div className="error-message text-danger">
                            {Object.keys(errors).map((key, index) => (
                            <p class="text-danger" key={index}>{errors[key]}</p>
                            ))}
                        </div>
                        )}
                                 <div class="col-sm-12">
								<div class="form-group form-check banner-checkbox">
									<input type="checkbox" class="form-check-input" id="exampleCheck6" checked={isChecked} disabled={!isCheckboxEnabled}
                                        onChange={handleCheckboxChange} />
									<label class="form-check-label" for="exampleCheck6">By Signing up you agree to our <a data-toggle="modal" data-target="#exampleModal" class="homebanner-from text-primary"> Term and condition & Privacy Policy.<i class="fas fa-info-circle"  data-toggle="tooltip" data-placement="top" title="Read the Terms and Conditions before proceeding"></i></a></label>
								  </div>
								  </div>
                    <button type="submit" disabled={!isChecked} className="btn btn-primary w-100 py-3">Sign Up</button>
                    <div className="bottom-link pt-4 text-center">
                        <p>Already have an account? <a href="/dealerin" className="w-100 py-3">Sign In</a></p>
                    </div>
                </form>
            </div>
            <div class="modal fade terms-modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
<h3>Terms and Conditions</h3>
<strong>Effective Date: November 1, 2024</strong>
<p>Welcome to CarChaser.ca, a premier online marketplace where private sellers auction their vehicles directly to licensed auto dealers in a 95-minute live auction. By accessing or using our services, you agree to be bound by these Terms and Conditions. By agreeing to these Terms, you acknowledge entering into a binding agreement with CarChaser.ca (“we,” “us,” or “our”).</p>
        <p>If you do not agree to these Terms, you must refrain from using our platform or services.</p>
<h3>1. Online Terms</h3>
<ul>
<li>CarChaser.ca facilitates vehicle auctions between private sellers and auto dealers.</li>
<li>By using our services, you agree to these Terms and affirm that you are a licensed auto dealer
authorized to participate in such transactions.</li>
</ul>

<h3>2. General Conditions</h3>
<ul>
<li>CarChaser.ca reserves the right to refuse service to anyone at its sole discretion.</li>
<li>You agree to use the platform in compliance with all applicable laws, regulations, and these Terms.</li>
<li>Misrepresentation, fraudulent activity, or any breach of these Terms may result in termination of access and potential legal action.</li>
</ul>

<h3>3. Auction Process and Terms</h3>
<ul>
<li>Once a reserve price for a vehicle is met or exceeded during an auction, the sale is considered final and binding</li>
<li>Bids cannot be retracted or withdrawn after they are placed.</li>
<li>Dealers are responsible for the prompt completion of all purchases made on the platform.</li>
</ul>

<h3>4. Fees and Charges</h3>
<ul>
<li>CarChaser.ca charges a flat fee plus applicable HST, based on the vehicle&#39;s value, for every car purchased via the platform.</li>
<li>Fees are non-refundable, except as expressly provided for in these Terms.</li>
</ul>

<h3>5. Arbitration Policy</h3>
<ul>
<li>Dealers have five (5) business days from the date of purchase to file for arbitration regarding disputes such as undisclosed damages or material misrepresentations.</li>
<li>CarChaser.ca will mediate arbitration claims, which may result in compensation to the buyer or return of the vehicle to the seller.</li>
</ul>

<h3>6. Accuracy, Completeness, and Timeliness of Information</h3>
<ul>
<li>Dealers are responsible for ensuring the accuracy of the information provided during account registration and auction transactions.</li>
<li>CarChaser.ca is not liable for errors or inaccuracies in auction listings or dealer-submitted information.</li>
</ul>

<h3>7. Modifications to Services and Pricing</h3>
<ul>
<li>CarChaser.ca reserves the right to modify, suspend, or discontinue any service at any time without prior notice.</li>
<li>Fees and charges may be adjusted with reasonable notice posted on the platform.</li>
</ul>

<h3>8. Use of Third-Party Tools and Links</h3>
<ul>
<li>CarChaser.ca may provide access to third-party tools or links to external websites. These tools and links are offered “as is,” and CarChaser.ca is not liable for issues arising from their use.</li>
</ul>

<h3>9. Disclaimer of Warranties and Limitation of Liability</h3>
<ul>
<li>CarChaser.ca provides its platform and services on an “as is” basis without warranties of any kind, express or implied.</li>
<li>CarChaser.ca is not responsible for any direct or indirect damages arising from your use of the platform or participation in auctions.</li>
<li>CarChaser.ca disclaims liability for the accuracy or completeness of vehicle descriptions provided by sellers.</li>
</ul>

<h3>10. Indemnification</h3>
<p>You agree to indemnify and hold CarChaser.ca harmless from all claims, damages, or losses arising out of:</p>
<ul>
<li>Your breach of these Terms.</li>
<li>Your participation in auctions or transactions.</li>
<li>Misrepresentation or failure to fulfill purchase obligations.</li>
</ul>

<h3>11. Termination</h3>
<ul>
<li>CarChaser.ca may terminate or suspend your access to the platform immediately and without notice if you breach these Terms. Obligations incurred prior to termination, including payment of fees, will survive termination.</li>
</ul>

<h3>12. Entire Agreement</h3>
<ul>
<li>These Terms constitute the entire agreement between you and CarChaser.ca regarding your use of the platform and supersede all prior agreements.</li>
</ul>

<h3>13. Governing Law and Jurisdiction</h3>
<ul>
<li>These Terms are governed by the laws of Ontario, Canada, and disputes will be subject to the jurisdiction of Ontario courts.</li>
</ul>

<h3>14. Changes to Terms and Conditions</h3>
<ul>
<li>CarChaser.ca reserves the right to amend these Terms. Changes will be posted on the platform, and continued use of the platform constitutes acceptance of updated Terms.</li>
</ul>
<h3>Privacy Policy</h3>
<strong>Effective Date: November 1, 2024</strong>
<p>At CarChaser.ca, we respect your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data.</p>
<h3>1. Information We Collect</h3>
<h4>1.1 Personal Information:</h4>
<ul>
   <li>Name, contact details, payment information, and dealer license information for account setup and transactions.</li>
</ul>
<h4>1.2 Transaction Information:</h4>
<ul>
   <li>Details of vehicle purchases, including VIN, make, model, and auction activity.</li>
</ul>
<h4>1.3 Usage Data:</h4>
<ul>
   <li>IP addresses, browser types, and activity logs for performance monitoring and security.</li>
</ul>
<h3>2. How We Use Your Information</h3>
<p>We use your information to:</p>
<ul>
<li>Facilitate auctions and transactions.</li>
<li>Communicate with you regarding auction activity or support inquiries.</li>
<li>Enforce compliance with these Terms.</li>
<li>Comply with applicable legal obligations.</li>
</ul>

<h3>3. Sharing Information</h3>
<p>We may share your information with:</p>
<ul>
<li><strong>Service Providers:</strong> For payment processing and customer support.</li>
<li><strong>Sellers:</strong> To facilitate auction-related communication.</li>
<li><strong>Regulatory Authorities:</strong> As required to comply with laws or investigations.</li>
</ul>
        <p>We will never sell your personal information to third parties.</p>

<h3>4. Data Security</h3>
        <p>CarChaser.ca uses industry-standard security measures to protect your personal data. However, no method of transmission or storage is entirely secure, and we cannot guarantee absolute protection.</p>

        <h3>5. Cookies</h3>
        <p>CarChaser.ca uses cookies to enhance platform functionality and analyze user behavior. You can manage cookie preferences through your browser settings.</p>
       
<h3>6. Your Rights</h3>
<p>You have the right to:</p>
<ul>
<li>Access and update your personal information.</li>
<li>Request deletion of your data, subject to legal retention obligations.</li>
<li>Opt out of non-essential communications.</li>
</ul>
       
<h3>7. Policy Updates</h3>
<p>This Privacy Policy may be updated periodically. Changes will take effect upon posting to the platform, and continued use constitutes acceptance.</p>

<h3>8. Contact Us</h3>
<p>For questions or concerns about these Terms or our Privacy Policy, contact us at:</p>
        <ul>
<li><strong>Email :</strong> <a href="mailto:contact@carchaser.ca">contact@carchaser.ca</a></li>
<li><strong>Phone :</strong> <a href="tel:6476896208">647-689-6208</a></li>
<li><strong>Address:</strong> 801-1275 Finch Ave W, ON Toronto M3J 0L5</li>
</ul>
      </div>
      <div class="modal-footer">
      <button
          type="button"
          className="btn btn-primary"
          data-dismiss="modal"
          onClick={handleOkClick} // Enable checkbox on OK click
        >
          Ok
        </button>
      </div>
    </div>
  </div>
</div>


        </section>
    );
};

import React, { useState, useEffect } from "react";
import { SellerNav } from "./DealerNav";
import api from "../api";
import { useNavigate } from "react-router-dom"; // Ensure this is imported
import { useParams } from "react-router-dom"; // import useParams

export const NagitionBids = () => {
  const { Vehicle_Id } = useParams(); // Get the ID from the URL slug
  const dealerId = sessionStorage.getItem("user_id");
  const [bidNegotiations, setBidNegotiations] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    BidFetchData();
  }, []);

  const BidFetchData = async () => {
    try {
      const response = await api.get(`/show-negotiation/${Vehicle_Id}`);
      setBidNegotiations(response.data.bidnegotation);
      console.log(response.data)
    } catch (error) {
      console.error("Error fetching Bid details:", error);
    }
  };

  // Separate records into your negotiation and other dealer's negotiation
  const yourNegotiation = bidNegotiations.find(
    (negotiation) => negotiation.dealer_id === Number(dealerId)
  );
  const otherNegotiation = bidNegotiations.find(
    (negotiation) => negotiation.dealer_id !== Number(dealerId)
  );
  const handleAction = async (actionType) => {
    if (yourNegotiation) {
      const payload = {
        BidID: yourNegotiation.bid_id,
        VehicleID: yourNegotiation.vehicle_id,
        DealerID: yourNegotiation.dealer_id,
        SellerID: yourNegotiation.seller_id,
        CounterPrice: yourNegotiation.dealer_counter_offer || yourNegotiation.seller_counter_offer,
        Comments: yourNegotiation.dealer_comments || "No comments",
        Action: actionType, // Accept or Decline
        PortalName: "Dealer",
      };

      try {
        const response = await api.post("/negotiation", payload);
        console.log("Response from server:", response);
        // Optionally refresh data after action
        BidFetchData();
      } catch (error) {
        console.error("Error submitting negotiation action:", error);
      }
    }
  };

  const handleNewCounterPrice = () => {
    if (yourNegotiation) {
      const negotiationData = {
        bidId: yourNegotiation.bid_id,
        dealerId: yourNegotiation.dealer_id,
        sellerId: yourNegotiation.seller_id,
        vehicleId: yourNegotiation.vehicle_id,
      };
      
      // Navigate to the negotiation popup page with negotiation data
      navigate("/nagition-bid-pop", { state: negotiationData });
    }
  };
  return (
    <section className="car-details">
      <SellerNav />
      <div className="container">
        <div className="row mt-4">
          {/* Your Negotiation */}
          <div className="col-md-12 text-center">
            <h2>My Negotiation</h2>
            {yourNegotiation ? (
              <>
                <p>Counter Price: {yourNegotiation.dealer_counter_offer || "Not Started Yet"}</p>
                <p>Comments: {yourNegotiation.dealer_comments || "Not Started Yet"}</p>
                <a className="btn btn-primary" onClick={handleNewCounterPrice}>Set New Counter Price</a>
              </>
            ) : (
              <p>No negotiations found for your dealer.</p>
            )}
          </div>
          <hr />
          {/* Seller's Original Offer */}
          <div className="col-md-12 text-center">
            <h2>Seller Negotiation</h2>
            <p>Counter Price: {bidNegotiations[0]?.seller_counter_offer}</p>
            <p>Comments: {bidNegotiations[0]?.seller_comments}</p>
            <a className="btn btn-primary mr-3" onClick={() => handleAction("Accept")}>Accept</a>
                <a className="btn btn-primary" onClick={() => handleAction("Decline")}>Decline</a>
          </div>
          <hr />
          {/* Other Dealer's Negotiation 
          <div className="col-md-12 text-center">
            <h2>Other Dealer Negotiation</h2>
            {otherNegotiation ? (
              <>
                <p>Counter Price: {otherNegotiation.dealer_counter_offer || "Not Started Yet"}</p>
                <p>Comments: {otherNegotiation.dealer_comments || "Not Started Yet"}</p>
              </>
            ) : (
              <p>No negotiations found for other dealers.</p>
            )}
          </div>*/}
        </div>
      </div>
    </section>
  );
};
